@charset "utf-8";


//
// Use Fira fonts: https://github.com/mmistakes/so-simple-theme#customizing-sass-scss
// See https://github.com/mozilla/Fira and http://mozilla.github.io/Fira/
//

$serif-font-family: "Fira Sans", serif;
$sans-serif-font-family: "Fira Sans", sans-serif;
$monospace-font-family: "Fira Code", Menlo, Consolas, Monaco, "Courier New", Courier,
  monospace;

$base-font-family: $sans-serif-font-family;
$headline-font-family: $sans-serif-font-family;
$title-font-family: $serif-font-family;
$description-font-family: $serif-font-family;
$meta-font-family: $serif-font-family;

@import "so-simple";
